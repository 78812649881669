import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { EMPTY, forkJoin, Observable, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { Company, CompanyService } from 'src/app/services/company.service';
import { LocalStorageService, StorageKeys } from 'src/app/services/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyHomeResolver {
  constructor(
    private router: Router,
    private authService: AuthService,
    private companyService: CompanyService,
    private localStorageService: LocalStorageService,
  ) {}

  resolve(routeSnapshot: ActivatedRouteSnapshot): Observable<{ company: Company; showSnackbar: boolean }> {
    const redirect = this.localStorageService.get(StorageKeys.REDIRECT_URL);
    let showSnackbar$: Observable<boolean> = of(false);
    if (redirect) {
      this.localStorageService.remove(StorageKeys.REDIRECT_URL);
      showSnackbar$ = this.authService.user$.pipe(
        take(1),
        map((user) => {
          if (!user) {
            return true;
          } else {
            this.router.navigate([redirect]);
            return false;
          }
        }
      ));
    }

    const slug = routeSnapshot.paramMap.get('slug') || routeSnapshot.queryParamMap.get('slug');
    return forkJoin({
      company: this.companyService.getCompanyBySlug(slug),
      showSnackbar: showSnackbar$,
    }).pipe(
      catchError((error) => {
        console.error(error);
        this.router.navigate(['/']);
        return EMPTY;
      })
    );
  }
}
