import { Injectable } from '@angular/core';
import { API_GET_ICD, ApiService } from './api.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class IcdService {
  constructor(private apiService: ApiService) {}

  isValidIcd(icd: string): Observable<boolean> {
    return this.apiService.http.get<GetIcdResponse>(API_GET_ICD(icd)).pipe(
      map((response: GetIcdResponse) => {
        if (response.icd) {
          return true;
        }
        return false;
      }),
      catchError((error) => {
        if (error.status === 404) {
          return of(false);
        }
        return of(true);
      })
    );
  }
}

export class GetIcdResponse {
  icd: string;
  description: string;
}
